// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-foglalas-js": () => import("./../../../src/pages/foglalas.js" /* webpackChunkName: "component---src-pages-foglalas-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-rendszer-admin-js": () => import("./../../../src/pages/rendszer/admin.js" /* webpackChunkName: "component---src-pages-rendszer-admin-js" */)
}

